import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import './index.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import LoadingProess from "@material-ui/core/CircularProgress"
// import App from './components/Navbar';
import App from './App3';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={"loading........"}>
      {/* <Suspense fallback={<LoadingProess />} > */}
      <BrowserRouter>
        <CssBaseline />
        <App />
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
