import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import { ChangeHistoryOutlined } from "@material-ui/icons";

const list = [
    {
        primaryText: "Home",
        icon: "home",
        path: '/'
    },
    {
        primaryText: "Shared with me",
        icon: "people",
        path: '/admin'
    },
    {
        primaryText: "Starred",
        icon: "star",
        path: '/'
    },
    {
        primaryText: "Recent",
        icon: "schedule",
        path: '/'
    },
    {
        primaryText: "Offline",
        icon: "offline_pin",
        path: '/'
    },
    {
        primaryText: "Uploads",
        icon: "publish",
        path: '/tools/fileupload'
    },
    {
        primaryText: "Report",
        icon: "backup",
        path: '/report/daily'
    },
];

const settingList = [
    {
        primaryText: "Setting",
        icon: "settings",
        path: '/setting'
    },
    {
        primaryText: "Login",
        icon: "login",
        path: '/login'
    },
    {
        primaryText: "Logout",
        icon: "logout",
        path: '/logout'
    }
]

// const NavContentEx = () => (
export function NavContentEx(props) {

    const changeTitle = (title) => {
        // console.log(title.primaryText)
        props.HeaderTitle(title.primaryText);

    }


    return (
        <List>
            {list.map(({ primaryText, icon, path }, i) => (
                <ListItem key={primaryText} selected={i === 0} button component={Link} to={path} onClick={() => changeTitle({ primaryText })}>
                    <ListItemIcon>
                        <Icon>{icon}</Icon>
                    </ListItemIcon>
                    <ListItemText
                        primary={primaryText}
                        primaryTypographyProps={{ noWrap: true }}
                    />
                </ListItem>
            ))}
            <Divider style={{ margin: "12px 0" }} />
            {settingList.map(({ primaryText, icon, path }, i) => (
                <ListItem key={primaryText} selected={i === 0} button component={Link} to={path} onClick={() => changeTitle({ primaryText })}>
                    <ListItemIcon>
                        <Icon>{icon}</Icon>
                    </ListItemIcon>
                    <ListItemText
                        primary={primaryText}
                        primaryTypographyProps={{ noWrap: true }}
                    />
                </ListItem>
            ))}
        </List>
    )
};


NavContentEx.propTypes = {};
NavContentEx.defaultProps = {};

export default NavContentEx;
