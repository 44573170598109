import React, { useState, lazy } from "react";
import { Switch, Route, useLocation, Link, Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
    // HeaderMockUp,
    // NavHeaderMockUp,
    // NavContentMockUp,
    // ContentMockUp,
    FooterMockUp
} from "@mui-treasury/mockup/layout";

import Layout, {
    Root,
    getHeader,
    getDrawerSidebar,
    getSidebarTrigger,
    getSidebarContent,
    getCollapseBtn,
    getContent,
    getFooter,
    // getStandardScheme
} from "@mui-treasury/layout";

// Nav Content
import NavContent from "./components/NavContent";

// Header Bar
import HeaderEx from "./components/Header";

//Pages
const Home = lazy(() => import("./pages/Home"));
const Admin = lazy(() => import("./pages/Admin"));
const Login = lazy(() => import("./pages/Login2"));
// const Signup = lazy(() => import("./pages/Signup"));
const AccountDailyReport = lazy(() => import("./pages/Report/AccountDaily"));
const ToolsCorsTest = lazy(() => import("./pages/Tools/crostest"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Credits = lazy(() => import("./pages/Admin/Credit"));
const Deposits = lazy(() => import("./pages/Admin/Deposit"));
const ToolsFileUpload = lazy(() => import("./pages/FileUpload"));

const Header = getHeader(styled);
const DrawerSidebar = getDrawerSidebar(styled);
const SidebarTrigger = getSidebarTrigger(styled);
const SidebarContent = getSidebarContent(styled);
const CollapseBtn = getCollapseBtn(styled);
const Content = getContent(styled);
const Footer = getFooter(styled);

const Dashboard = () => {

    const [isLoading, setLoading] = useState(false);
    const location = useLocation();
    const scheme = Layout();
    const [title, setTitle] = useState('Home');

    let history = useHistory();

    scheme.configureHeader(builder => {
        builder
            .registerConfig("xs", {
                position: "sticky"
            })
            .registerConfig("md", {
                position: "relative" // won't stick to top when scroll down
            });
    });

    scheme.configureEdgeSidebar(builder => {
        builder
            .create("unique_id", { anchor: "left" })
            .registerTemporaryConfig("xs", {
                anchor: "left",
                width: "auto" // 'auto' is only valid for temporary variant
            })
            .registerPermanentConfig("md", {
                width: 256, // px, (%, rem, em is compatible)
                collapsible: true,
                collapsedWidth: 64
            });
    });

    scheme.enableAutoCollapse("unique_id", "md");

    //Auth check
    const authGuard = (Component) => () => {
        return localStorage.getItem("token") ? (
            <Component />
        ) : (
                <Redirect to="/login" />
            );
    };

    function logOut() {
        localStorage.removeItem('token');
        history.push("/")
        // Hard reload
        window.location.reload(false);
    }

    // update Header Title
    function changeHeaderTitle(mytitle) {
        setTitle(mytitle)
    }



    return isLoading ? (
        <div>Loading.....</div>
    ) : (
            <Root scheme={scheme}>
                {({ state: { sidebar } }) => (
                    <>
                        <CssBaseline />
                        <Header>
                            <Toolbar>
                                <SidebarTrigger sidebarId="unique_id" />
                                <HeaderEx title={title} />
                            </Toolbar>
                        </Header>
                        <DrawerSidebar sidebarId="unique_id">
                            <SidebarContent>
                                {/* <NavHeaderMockUp collapsed={sidebar.unique_id.collapsed} /> */}
                                {/* <NavContentMockUp /> */}
                                <NavContent
                                    collapsed={sidebar.unique_id.collapsed}
                                    HeaderTitle={(myttile) => changeHeaderTitle(myttile)}
                                />
                            </SidebarContent>

                            <CollapseBtn />
                        </DrawerSidebar>
                        <Content>
                            {location.pathname !== "/" && (
                                <div>
                                    <Link to="/">Back to home</Link>
                                </div>
                            )}
                            {/* <Switch>
                            <Route path="/" exact render={() => <h1>Home</h1>} />
                            <Route path="/page2" exact render={() => <h1>Page 2</h1>} />
                            <Route path="/page3" exact render={() => <h1>Page 3</h1>} />
                        </Switch> */}
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route path="/login" component={Login} />
                                <Route path="/logout" component={logOut} />
                                <Route path="/tools/cors" component={() => <ToolsCorsTest subreddit='HongKong' />} />
                                <Route path="/tools/fileupload" component={ToolsFileUpload} />
                                <Route exact path="/admin" render={authGuard(Admin)} />
                                <Route path="/dashboard" render={authGuard(Admin)} />
                                <Route path="/report/daily" render={authGuard(AccountDailyReport)} />
                                <Route exact path="/admin/credit/all" render={authGuard(Credits)} />
                                <Route exact path="/deposit" component={Deposits} />
                                <Route path="*" component={NotFound} />
                            </Switch>
                            {/* <ContentMockUp /> */}
                        </Content>
                        <Footer>
                            <FooterMockUp />

                        </Footer>
                    </>
                )}
            </Root>
        );
};

export default Dashboard;
